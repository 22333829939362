import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    isTransactionsTransitionInvalidTransition,
    storableError,
} from '../../util/errors';
import {
    txIsEnquired,
    getReview1Transition,
    getReview2Transition,
    txIsInFirstReviewBy,
    // TRANSITION_ACCEPT,
    // TRANSITION_DECLINE,
    // TRANSITION_REQUEST_CANCEL,
    TRANSITION_CANCEL_ENQUIRY,
    TRANSITION_DECLINE_ENQUIRY,
    TRANSITION_ACCEPT_FILE_OFFER,
} from '../../util/transaction';
import {
    transactionLineItems,
    transitionPrivileged,
    fetchFiles as getTxFiles,
    uploadFile as uploadTxFile,
    fetchSysMsgs as fetchTxSysMsgs,
    createSysMsg as createTxSysMsg,
    fetchOffers as fetchTxOffers,
    createOffer as createTxOffer,
    updateOffer as updateTxOffer,
    updateMetadata,
    createEmailReminder,
} from '../../util/api';
import * as log from '../../util/log';
import {
    updatedEntities,
    denormalisedEntities,
    denormalisedResponseEntities,
} from '../../util/data';
import {
    findNextBoundary,
    nextMonthFn,
    monthIdStringInTimeZone,
} from '../../util/dates';
import {
    sendInquiryAcceptedByHostEmail,
    sendOfferCreatedEmail,
    addHoursToDate,
    getG4ParamsFromTx,
} from '../../util/misc';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

const formatInquiryDate = (dateStamp) => {
    if (!dateStamp) return "";
    const dString = new Date(Number(dateStamp)).toLocaleDateString('en-GB');
    const dTime = new Date(Number(dateStamp)).toLocaleTimeString('en-US');
    return `${dString} ${dTime}`;
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST =
    'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS =
    'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR =
    'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST =
    'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS =
    'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR =
    'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST =
    'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS =
    'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const FETCH_FILES_REQUEST = 'app/TransactionPage/FETCH_FILES_REQUEST';
export const FETCH_FILES_SUCCESS = 'app/TransactionPage/FETCH_FILES_SUCCESS';
export const FETCH_FILES_ERROR = 'app/TransactionPage/FETCH_FILES_ERROR';

export const SEND_FILE_REQUEST = 'app/TransactionPage/SEND_FILE_REQUEST';
export const SEND_FILE_SUCCESS = 'app/TransactionPage/SEND_FILE_SUCCESS';
export const SEND_FILE_ERROR = 'app/TransactionPage/SEND_FILE_ERROR';

export const FETCH_SYS_MSG_REQUEST = 'app/TransactionPage/FETCH_SYS_MSG_REQUEST';
export const FETCH_SYS_MSG_SUCCESS = 'app/TransactionPage/FETCH_SYS_MSG_SUCCESS';
export const FETCH_SYS_MSG_ERROR = 'app/TransactionPage/FETCH_SYS_MSG_ERROR';

export const CREATE_SYS_MSG_REQUEST = 'app/TransactionPage/CREATE_SYS_MSG_REQUEST';
export const CREATE_SYS_MSG_SUCCESS = 'app/TransactionPage/CREATE_SYS_MSG_SUCCESS';
export const CREATE_SYS_MSG_ERROR = 'app/TransactionPage/CREATE_SYS_MSG_ERROR';

export const FETCH_OFFERS_REQUEST = 'app/TransactionPage/FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'app/TransactionPage/FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_ERROR = 'app/TransactionPage/FETCH_OFFERS_ERROR';

export const SEND_OFFER_REQUEST = 'app/TransactionPage/SEND_OFFER_REQUEST';
export const SEND_OFFER_SUCCESS = 'app/TransactionPage/SEND_OFFER_SUCCESS';
export const SEND_OFFER_ERROR = 'app/TransactionPage/SEND_OFFER_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST =
    'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS =
    'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR =
    'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST =
    'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
    'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR =
    'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

// ================ Reducer ================ //

const initialState = {
    fetchTransactionInProgress: false,
    fetchTransactionError: null,
    transactionRef: null,
    acceptInProgress: false,
    acceptSaleError: null,
    declineInProgress: false,
    declineSaleError: null,
    fetchMessagesInProgress: false,
    fetchMessagesError: null,
    totalMessages: 0,
    totalMessagePages: 0,
    oldestMessagePageFetched: 0,
    messages: [],
    fetchFilesInProgress: false,
    fetchFilesError: null,
    files: [],
    tempFileId: null,
    sendFileInProgress: false,
    sendFileError: null,
    fetchSysMsgInProgress: false,
    fetchSysMsgError: null,
    sysMsgs: [],
    createSysMsgInProgress: false,
    createSysMsgError: null,
    fetchOffersInProgress: false,
    fetchOffersError: null,
    offers: [],
    sendOfferInProgress: false,
    sendOfferError: null,
    initialMessageFailedToTransaction: null,
    savePaymentMethodFailed: false,
    sendMessageInProgress: false,
    sendMessageError: null,
    sendReviewInProgress: false,
    sendReviewError: null,
    monthlyTimeSlots: {
        // '2019-12': {
        //   timeSlots: [],
        //   fetchTimeSlotsError: null,
        //   fetchTimeSlotsInProgress: null,
        // },
    },
    fetchTransitionsInProgress: false,
    fetchTransitionsError: null,
    processTransitions: null,
    lineItems: null,
    fetchLineItemsInProgress: false,
    fetchLineItemsError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
    return a
        .filter(
            (aEntity) =>
                !b.find((bEntity) => aEntity.id.uuid === bEntity.id.uuid)
        )
        .concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_VALUES:
            return { ...initialState, ...payload };

        case FETCH_TRANSACTION_REQUEST:
            return {
                ...state,
                fetchTransactionInProgress: true,
                fetchTransactionError: null,
            };
        case FETCH_TRANSACTION_SUCCESS: {
            const transactionRef = {
                id: payload.data.data.id,
                type: 'transaction',
            };
            return {
                ...state,
                fetchTransactionInProgress: false,
                transactionRef,
            };
        }
        case FETCH_TRANSACTION_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchTransactionInProgress: false,
                fetchTransactionError: payload,
            };

        case FETCH_TRANSITIONS_REQUEST:
            return {
                ...state,
                fetchTransitionsInProgress: true,
                fetchTransitionsError: null,
            };
        case FETCH_TRANSITIONS_SUCCESS:
            return {
                ...state,
                fetchTransitionsInProgress: false,
                processTransitions: payload,
            };
        case FETCH_TRANSITIONS_ERROR:
            console.error(payload); // eslint-disable-line
            return {
                ...state,
                fetchTransitionsInProgress: false,
                fetchTransitionsError: payload,
            };

        case ACCEPT_SALE_REQUEST:
            return {
                ...state,
                acceptInProgress: true,
                acceptSaleError: null,
                declineSaleError: null,
            };
        case ACCEPT_SALE_SUCCESS:
            return { ...state, acceptInProgress: false };
        case ACCEPT_SALE_ERROR:
            return {
                ...state,
                acceptInProgress: false,
                acceptSaleError: payload,
            };

        case DECLINE_SALE_REQUEST:
            return {
                ...state,
                declineInProgress: true,
                declineSaleError: null,
                acceptSaleError: null,
            };
        case DECLINE_SALE_SUCCESS:
            return { ...state, declineInProgress: false };
        case DECLINE_SALE_ERROR:
            return {
                ...state,
                declineInProgress: false,
                declineSaleError: payload,
            };

        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                fetchMessagesInProgress: true,
                fetchMessagesError: null,
            };
        case FETCH_MESSAGES_SUCCESS: {
            const oldestMessagePageFetched =
                state.oldestMessagePageFetched > payload.page
                    ? state.oldestMessagePageFetched
                    : payload.page;
            return {
                ...state,
                fetchMessagesInProgress: false,
                messages: mergeEntityArrays(state.messages, payload.messages),
                totalMessages: payload.totalItems,
                totalMessagePages: payload.totalPages,
                oldestMessagePageFetched,
            };
        }
        case FETCH_MESSAGES_ERROR:
            return {
                ...state,
                fetchMessagesInProgress: false,
                fetchMessagesError: payload,
            };

        case SEND_MESSAGE_REQUEST:
            return {
                ...state,
                sendMessageInProgress: true,
                sendMessageError: null,
                initialMessageFailedToTransaction: null,
            };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, sendMessageInProgress: false };
        case SEND_MESSAGE_ERROR:
            return {
                ...state,
                sendMessageInProgress: false,
                sendMessageError: payload,
            };

        case FETCH_FILES_REQUEST:
            return { ...state, fetchFilesInProgress: true, fetchFilesError: null };
        case FETCH_FILES_SUCCESS: {
            return {
                ...state,
                fetchFilesInProgress: false,
                files: payload.files,
            };
        }
        case FETCH_FILES_ERROR:
            return { ...state, fetchFilesInProgress: false, fetchFilesError: payload };

        case SEND_FILE_REQUEST:
            return {
                ...state,
                sendFileInProgress: true,
                sendFileError: null,
                tempFileId: null,
            };
        case SEND_FILE_SUCCESS:
            return { ...state, sendFileInProgress: false, tempFileId: payload };
        case SEND_FILE_ERROR:
            return { ...state, sendFileInProgress: false, sendFileError: payload };

        case FETCH_SYS_MSG_REQUEST:
            return { ...state, fetchSysMsgInProgress: true, fetchSysMsgError: null };
        case FETCH_SYS_MSG_SUCCESS: {
            return {
                ...state,
                fetchSysMsgInProgress: false,
                sysMsgs: payload.sysMsgs,
            };
        }
        case FETCH_SYS_MSG_ERROR:
            return { ...state, fetchSysMsgInProgress: false, fetchSysMsgError: payload };

        case CREATE_SYS_MSG_REQUEST:
            return {
                ...state,
                createSysMsgInProgress: true,
                createSysMsgError: null,
            };
        case CREATE_SYS_MSG_SUCCESS:
            return { ...state, createSysMsgInProgress: false };
        case CREATE_SYS_MSG_ERROR:
            return { ...state, createSysMsgInProgress: false, createSysMsgError: payload };

        case FETCH_OFFERS_REQUEST:
            return { ...state, fetchOffersInProgress: true, fetchOffersError: null };
        case FETCH_OFFERS_SUCCESS: {
            return {
                ...state,
                fetchOffersInProgress: false,
                offers: payload.offers,
            };
        }
        case FETCH_OFFERS_ERROR:
            return { ...state, fetchOffersInProgress: false, fetchOffersError: payload };

        case SEND_OFFER_REQUEST:
            return {
                ...state,
                sendOfferInProgress: true,
                sendOfferError: null,
            };
        case SEND_OFFER_SUCCESS:
            return { ...state, sendOfferInProgress: false, tempFileId: null };
        case SEND_OFFER_ERROR:
            return { ...state, sendOfferInProgress: false, sendOfferError: payload };

        case SEND_REVIEW_REQUEST:
            return {
                ...state,
                sendReviewInProgress: true,
                sendReviewError: null,
            };
        case SEND_REVIEW_SUCCESS:
            return { ...state, sendReviewInProgress: false };
        case SEND_REVIEW_ERROR:
            return {
                ...state,
                sendReviewInProgress: false,
                sendReviewError: payload,
            };

        case FETCH_TIME_SLOTS_REQUEST: {
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [payload]: {
                    ...state.monthlyTimeSlots[payload],
                    fetchTimeSlotsError: null,
                    fetchTimeSlotsInProgress: true,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_SUCCESS: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    timeSlots: payload.timeSlots,
                },
            };
            return { ...state, monthlyTimeSlots };
        }
        case FETCH_TIME_SLOTS_ERROR: {
            const monthId = payload.monthId;
            const monthlyTimeSlots = {
                ...state.monthlyTimeSlots,
                [monthId]: {
                    ...state.monthlyTimeSlots[monthId],
                    fetchTimeSlotsInProgress: false,
                    fetchTimeSlotsError: payload.error,
                },
            };
            return { ...state, monthlyTimeSlots };
        }

        case FETCH_LINE_ITEMS_REQUEST:
            return {
                ...state,
                fetchLineItemsInProgress: true,
                fetchLineItemsError: null,
            };
        case FETCH_LINE_ITEMS_SUCCESS:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                lineItems: payload,
            };
        case FETCH_LINE_ITEMS_ERROR:
            return {
                ...state,
                fetchLineItemsInProgress: false,
                fetchLineItemsError: payload,
            };

        default:
            return state;
    }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = (state) => {
    return (
        state.TransactionPage.acceptInProgress ||
        state.TransactionPage.declineInProgress
    );
};

// ================ Action creators ================ //
export const setInitialValues = (initialValues) => ({
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = (response) => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: response,
});
const fetchTransactionError = (e) => ({
    type: FETCH_TRANSACTION_ERROR,
    error: true,
    payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = (response) => ({
    type: FETCH_TRANSITIONS_SUCCESS,
    payload: response,
});
const fetchTransitionsError = (e) => ({
    type: FETCH_TRANSITIONS_ERROR,
    error: true,
    payload: e,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = (e) => ({
    type: ACCEPT_SALE_ERROR,
    error: true,
    payload: e,
});

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = (e) => ({
    type: DECLINE_SALE_ERROR,
    error: true,
    payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload: { messages, ...pagination },
});
const fetchMessagesError = (e) => ({
    type: FETCH_MESSAGES_ERROR,
    error: true,
    payload: e,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = (e) => ({
    type: SEND_MESSAGE_ERROR,
    error: true,
    payload: e,
});

const fetchFilesRequest = () => ({ type: FETCH_FILES_REQUEST });
const fetchFilesSuccess = (files) => ({
    type: FETCH_FILES_SUCCESS,
    payload: { files },
});
const fetchFilesError = e => ({ type: FETCH_FILES_ERROR, error: true, payload: e });

const sendFileRequest = () => ({ type: SEND_FILE_REQUEST });
const sendFileSuccess = (fileId) => ({ type: SEND_FILE_SUCCESS, payload: fileId });
const sendFileError = e => ({ type: SEND_FILE_ERROR, error: true, payload: e });

const fetchSysMsgRequest = () => ({ type: FETCH_SYS_MSG_REQUEST });
const fetchSysMsgSuccess = (sysMsgs) => ({
    type: FETCH_SYS_MSG_SUCCESS,
    payload: { sysMsgs },
});
const fetchSysMsgError = e => ({ type: FETCH_SYS_MSG_ERROR, error: true, payload: e });

const createSysMsgRequest = () => ({ type: CREATE_SYS_MSG_REQUEST });
const createSysMsgSuccess = () => ({ type: CREATE_SYS_MSG_SUCCESS });
const createSysMsgError = e => ({ type: CREATE_SYS_MSG_ERROR, error: true, payload: e });

const fetchOffersRequest = () => ({ type: FETCH_OFFERS_REQUEST });
const fetchOffersSuccess = (offers) => ({
    type: FETCH_OFFERS_SUCCESS,
    payload: { offers },
});
const fetchOffersError = e => ({ type: FETCH_OFFERS_ERROR, error: true, payload: e });

const sendOfferRequest = () => ({ type: SEND_OFFER_REQUEST });
const sendOfferSuccess = () => ({ type: SEND_OFFER_SUCCESS });
const sendOfferError = e => ({ type: SEND_OFFER_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = (e) => ({
    type: SEND_REVIEW_ERROR,
    error: true,
    payload: e,
});

export const fetchTimeSlotsRequest = (monthId) => ({
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
    type: FETCH_TIME_SLOTS_ERROR,
    error: true,
    payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = (lineItems) => ({
    type: FETCH_LINE_ITEMS_SUCCESS,
    payload: lineItems,
});
export const fetchLineItemsError = (error) => ({
    type: FETCH_LINE_ITEMS_ERROR,
    error: true,
    payload: error,
});

// ================ Thunks ================ //

const timeSlotsRequest = (params) => (dispatch, getState, sdk) => {
    return sdk.timeslots.query(params).then((response) => {
        return denormalisedResponseEntities(response);
    });
};

export const fetchTimeSlots =
    (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
        const monthId = monthIdStringInTimeZone(start, timeZone);

        dispatch(fetchTimeSlotsRequest(monthId));

        // The maximum pagination page size for timeSlots is 500
        const extraParams = {
            per_page: 500,
            page: 1,
        };

        return dispatch(
            timeSlotsRequest({ listingId, start, end, ...extraParams })
        )
            .then((timeSlots) => {
                dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
            })
            .catch((e) => {
                dispatch(fetchTimeSlotsError(monthId, storableError(e)));
            });
    };

// Helper function for fetchTransaction call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
    const hasWindow = typeof window !== 'undefined';
    const attributes = listing.attributes;
    // Listing could be ownListing entity too, so we just check if attributes key exists
    const hasTimeZone =
        attributes &&
        attributes.availabilityPlan &&
        attributes.availabilityPlan.timezone;

    // Fetch time-zones on client side only.
    if (hasWindow && listing.id && hasTimeZone) {
        const tz = listing.attributes.availabilityPlan.timezone;
        const nextBoundary = findNextBoundary(tz, new Date());

        const nextMonth = nextMonthFn(nextBoundary, tz);
        const nextAfterNextMonth = nextMonthFn(nextMonth, tz);
        const nextAfterNextMonth2 = nextMonthFn(nextAfterNextMonth, tz);
        const nextAfterNextMonth3 = nextMonthFn(nextAfterNextMonth2, tz);

        return Promise.all([
            dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
            dispatch(
                fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)
            ),
            dispatch(
                fetchTimeSlots(listing.id, nextAfterNextMonth, nextAfterNextMonth2, tz)
            ),
            dispatch(
                fetchTimeSlots(listing.id, nextAfterNextMonth2, nextAfterNextMonth3, tz)
            ),
        ]);
    }

    // By default return an empty array
    return Promise.all([]);
};

const listingRelationship = (txResponse) => {
    return txResponse.data.data.relationships.listing.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
    dispatch(fetchTransactionRequest());
    let txResponse = null;

    return sdk.transactions
        .show(
            {
                id,
                include: [
                    'customer',
                    'customer.profileImage',
                    'provider',
                    'provider.profileImage',
                    'listing',
                    'booking',
                    'reviews',
                    'reviews.author',
                    'reviews.subject',
                ],
                ...IMAGE_VARIANTS,
            },
            { expand: true }
        )
        .then((response) => {
            txResponse = response;
            const listingId = listingRelationship(response).id;
            const entities = updatedEntities({}, response.data);
            const listingRef = { id: listingId, type: 'listing' };
            const transactionRef = { id, type: 'transaction' };
            const denormalised = denormalisedEntities(entities, [
                listingRef,
                transactionRef,
            ]);
            const listing = denormalised[0];
            const transaction = denormalised[1];

            // Fetch time slots for transactions that are in enquired state
            const canFetchTimeslots =
                txRole === 'customer' &&
                config.enableAvailability &&
                transaction &&
                txIsEnquired(transaction);

            if (canFetchTimeslots) {
                fetchMonthlyTimeSlots(dispatch, listing);
            }

            const canFetchListing =
                listing && listing.attributes && !listing.attributes.deleted;
            if (canFetchListing) {
                return sdk.listings.show({
                    id: listingId,
                    include: ['author', 'author.profileImage', 'images'],
                    ...IMAGE_VARIANTS,
                });
            } else {
                return response;
            }
        })
        .then((response) => {
            dispatch(addMarketplaceEntities(txResponse));
            dispatch(addMarketplaceEntities(response));
            dispatch(fetchTransactionSuccess(txResponse));
            return response;
        })
        .catch((e) => {
            dispatch(fetchTransactionError(storableError(e)));
            throw e;
        });
};

const setBookingReminderEmails = (tx_id) => (dispatch, getState, sdk) => {
    // set customer one time email
    createEmailReminder({
        tx_id,
        to_role: "customer",
        type: "once",
        email_type: "remindGuestToConfirmBooking",
        first_trigger_at: addHoursToDate(12),
        next_trigger_at: addHoursToDate(12),
    });

    return tx_id;
};

export const acceptSale = (transaction) => (dispatch, getState, sdk) => {
    if (acceptOrDeclineInProgress(getState())) {
        return Promise.reject(
            new Error('Accept or decline already in progress')
        );
    }
    dispatch(acceptSaleRequest());

    return updateMetadata({
        id: transaction.id.uuid,
        metaParams: { inquiryAccepted: true },
    })
        .then((response) => {
            dispatch(fetchTransaction(transaction.id, 'provider'));
            dispatch(acceptSaleSuccess());
            dispatch(fetchCurrentUserNotifications());
            dispatch(setBookingReminderEmails(transaction.id.uuid));

            const rawBookingData = transaction.attributes.metadata.rawBookingData || {};
            const {
                bookingStartTime,
                bookingEndTime,
            } = rawBookingData;
            sendInquiryAcceptedByHostEmail({
                customerId: transaction.customer.id.uuid,
                listingTitle: transaction.listing.attributes.title,
                providerName: transaction.provider.attributes.profile.displayName,
                customerName: transaction.customer.attributes.profile.displayName,
                transactionId: transaction.id.uuid,
                formattedStartDate: formatInquiryDate(bookingStartTime),
                formattedEndDate: formatInquiryDate(bookingEndTime),
            });
            return response;
        })
        .catch((e) => {
            dispatch(acceptSaleError(storableError(e)));
            throw e;
        });
};

export const declineSale =
    (id, isRequestCancel) => (dispatch, getState, sdk) => {
        if (acceptOrDeclineInProgress(getState())) {
            return Promise.reject(
                new Error('Accept or decline already in progress')
            );
        }
        dispatch(declineSaleRequest());
        const transition = isRequestCancel
            ? TRANSITION_CANCEL_ENQUIRY
            : TRANSITION_DECLINE_ENQUIRY;

        return sdk.transactions
            .transition({ id, transition, params: {} }, { expand: true })
            .then((response) => {
                dispatch(addMarketplaceEntities(response));
                dispatch(declineSaleSuccess());
                dispatch(fetchCurrentUserNotifications());
                return response;
            })
            .catch((e) => {
                dispatch(declineSaleError(storableError(e)));
                log.error(e, 'reject-sale-failed', {
                    txId: id,
                    transition,
                });
                throw e;
            });
    };

export const editInquiry = (id, newInquiry) => (dispatch, getState, sdk) => {
    if (acceptOrDeclineInProgress(getState())) {
        return Promise.reject(
            new Error('Accept or decline already in progress')
        );
    };
    dispatch(acceptSaleRequest());

    return updateMetadata({
        id: id.uuid,
        metaParams: { rawBookingData: newInquiry },
    })
        .then((response) => {
            dispatch(fetchTransaction(id, 'customer'));
            dispatch(acceptSaleSuccess());
            return response;
        })
        .catch((e) => {
            dispatch(acceptSaleError(storableError(e)));
            throw e;
        });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
    const paging = { page, per_page: MESSAGES_PAGE_SIZE };
    dispatch(fetchMessagesRequest());

    return sdk.messages
        .query({
            transaction_id: txId,
            include: ['sender', 'sender.profileImage'],
            ...IMAGE_VARIANTS,
            ...paging,
        })
        .then((response) => {
            const messages = denormalisedResponseEntities(response);
            const {
                totalItems,
                totalPages,
                page: fetchedPage,
            } = response.data.meta;
            const pagination = { totalItems, totalPages, page: fetchedPage };
            const totalMessages = getState().TransactionPage.totalMessages;

            // Original fetchMessages call succeeded
            dispatch(fetchMessagesSuccess(messages, pagination));

            // Check if totalItems has changed between fetched pagination pages
            // if totalItems has changed, fetch first page again to include new incoming messages.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            if (totalItems > totalMessages && page > 1) {
                dispatch(fetchMessages(txId, 1))
                    .then(() => {
                        // Original fetch was enough as a response for user action,
                        // this just includes new incoming messages
                    })
                    .catch(() => {
                        // Background update, no need to to do anything atm.
                    });
            }
        })
        .catch((e) => {
            dispatch(fetchMessagesError(storableError(e)));
            throw e;
        });
};

export const fetchMoreMessages = (txId) => (dispatch, getState, sdk) => {
    const state = getState();
    const { oldestMessagePageFetched, totalMessagePages } =
        state.TransactionPage;
    const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

    // In case there're no more old pages left we default to fetching the current cursor position
    const nextPage = hasMoreOldMessages
        ? oldestMessagePageFetched + 1
        : oldestMessagePageFetched;

    return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
    dispatch(sendMessageRequest());

    return sdk.messages
        .send({ transactionId: txId, content: message })
        .then((response) => {
            const messageId = response.data.data.id;

            // We fetch the first page again to add sent message to the page data
            // and update possible incoming messages too.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            return dispatch(fetchMessages(txId, 1))
                .then(() => {
                    dispatch(sendMessageSuccess());
                    return messageId;
                })
                .catch(() => dispatch(sendMessageSuccess()));
        })
        .catch((e) => {
            dispatch(sendMessageError(storableError(e)));
            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

export const fetchFiles = (tx_id) => (dispatch, getState, sdk) => {
    dispatch(fetchFilesRequest());

    return getTxFiles({ tx_id })
        .then(response => {
            return dispatch(fetchFilesSuccess(response));
        })
        .catch(e => {
            dispatch(fetchFilesError(e.response ? e.response : 'Unknown error'));
            throw e;
        });
};

export const uploadFile = (params) => (dispatch, getState, sdk) => {
    dispatch(sendFileRequest());

    return uploadTxFile(params)
        .then(response => {
            return dispatch(fetchFiles(response.tx_id))
                .then(() => {
                    dispatch(sendFileSuccess(response.fileId));
                })
                .catch(() => dispatch(sendFileSuccess()));
        })
        .catch(e => {
            dispatch(sendFileError('Failed to upload file'));
            throw e;
        });
};

export const fetchSysMsg = (tx_id) => (dispatch, getState, sdk) => {
    dispatch(fetchSysMsgRequest());

    return fetchTxSysMsgs({ tx_id })
        .then(response => {
            return dispatch(fetchSysMsgSuccess(response));
        })
        .catch(e => {
            dispatch(fetchSysMsgError(e.response ? e.response : 'Unknown error'));
            throw e;
        });
};

export const createSysMsg = (params) => (dispatch, getState, sdk) => {
    dispatch(createSysMsgRequest());

    return createTxSysMsg(params)
        .then(response => {
            return dispatch(fetchSysMsg(response.tx_id))
                .then(() => {
                    dispatch(createSysMsgSuccess());
                })
                .catch(() => dispatch(createSysMsgSuccess()));
        })
        .catch(e => {
            dispatch(createSysMsgError('Failed to create system message'));
            throw e;
        });
};

export const fetchOffers = (tx_id) => (dispatch, getState, sdk) => {
    dispatch(fetchOffersRequest());

    return fetchTxOffers({ tx_id })
        .then(response => {
            return dispatch(fetchOffersSuccess(response));
        })
        .catch(e => {
            dispatch(fetchOffersError(e.response ? e.response : 'Unknown error'));
            throw e;
        });
};

export const sendOffer = (params) => (dispatch, getState, sdk) => {
    dispatch(sendOfferRequest());
    const {
        offerParams,
        payin,
        transaction,
        intl,
        fileURL,
    } = params;

    return createTxOffer(offerParams)
        .then(response => {
            return dispatch(fetchOffers(response.tx_id))
                .then(() => {
                    dispatch(sendOfferSuccess());
                    sendOfferCreatedEmail({
                        intl,
                        offer: response.offer,
                        customerId: transaction.customer.id.uuid,
                        listingTitle: transaction.listing.attributes.title,
                        providerName: transaction.provider.attributes.profile.displayName,
                        customerName: transaction.customer.attributes.profile.displayName,
                        payinTotalAmount: payin,
                        fileURL,
                    });
                })
                .catch(() => dispatch(sendOfferSuccess()));
        })
        .catch(e => {
            dispatch(sendOfferError('Failed to send offer'));
            throw e;
        });
};

export const updateOffer = (params) => (dispatch, getState, sdk) => {
    dispatch(sendOfferRequest());

    return updateTxOffer(params)
        .then(response => {
            return dispatch(fetchOffers(response.tx_id))
                .then(() => {
                    if (params.action && params.action == "decline") {
                        dispatch(fetchSysMsg(response.tx_id));
                    };
                    dispatch(sendOfferSuccess());
                })
                .catch(() => dispatch(sendOfferSuccess()));
        })
        .catch(e => {
            dispatch(sendOfferError('Failed to update offer'));
            throw e;
        });
};

export const acceptFileOffer = (orderParams, transactionId) => (dispatch, getState, sdk) => {
    dispatch(sendOfferRequest());

    const {
        startDate: bookingStart,
        endDate: bookingEnd,
        activity,
        noOfGuest,
        offerId,
        offerPrice,
        offerType,
    } = orderParams;

    const bookingData = {
        startDate: bookingStart,
        endDate: bookingStart,
        activity,
        noOfGuest,
        offerPrice,
        offerType,
    };
    const bodyParams = {
        id: transactionId,
        transition: TRANSITION_ACCEPT_FILE_OFFER,
        params: {
            bookingStart,
            bookingEnd,
            ...orderParams
        },
    };

    const queryParams = {
        include: ['booking', 'provider'],
        expand: true,
    };

    return transitionPrivileged({
        isSpeculative: false,
        bookingData,
        bodyParams,
        queryParams,
    })
        .then(res => {
            dispatch(sendOfferSuccess());
            dispatch(updateOffer({
                tx_id: transactionId.uuid,
                offer_id: offerId,
                offerStatus: "accepted",
                action: "accept",
            }));
            dispatch(fetchTransaction(transactionId, "customer"));
            dispatch(handleG4({
                txId: transactionId,
                isFile: true,
                activity,
            }));
        })
        .catch(() => {
            dispatch(sendOfferError('Failed to accept offer'));
        });
};

export const handleG4 = ({
    txId,
    isFile,
    isNoOffer,
    activity,
    lineItems,
}) => (dispatch, getState, sdk) => {
    return sdk.transactions
    .show({
        id: txId,
        include: [
            'provider',
            'listing',
            'booking',
        ],
    })
    .then(res => {
        const provider = res.data.included.find(i => i.type == "user");
        const listing = res.data.included.find(i => i.type == "listing");
        const booking = res.data.included.find(i => i.type == "booking");

        const g4Params = getG4ParamsFromTx({
            event: "offer_accepted",
            provider,
            listing,
            tx: res.data.data,
            booking,
            addittional: isNoOffer ? {
                offer_type: 'standard',
                inquiry_type: 'standard',
            } : isFile ? {
                offer_type: 'individual offer',
                individual_offer: 'pdf',
                inquiry_type: 'standard',
            } : {
                offer_type: 'individual offer',
                individual_offer: 'standard',
                inquiry_type: 'standard'
            },
            activity,
            lineItems,
        });
        window.dataLayer.push({
            ...g4Params,
            transaction_id: txId.uuid,
        });
    })
    .catch(e => {
        console.error("G4 failed", e);
    })
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
    'fields.image': [
        // Profile images
        'variants.square-small',
        'variants.square-small2x',

        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
    const transition = getReview2Transition(role === CUSTOMER);

    const include = REVIEW_TX_INCLUDES;

    return sdk.transactions
        .transition(
            { id, transition, params },
            { expand: true, include, ...IMAGE_VARIANTS }
        )
        .then((response) => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess());
            return response;
        })
        .catch((e) => {
            dispatch(sendReviewError(storableError(e)));

            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, params, role, dispatch, sdk) => {
    const transition = getReview1Transition(role === CUSTOMER);
    const include = REVIEW_TX_INCLUDES;

    return sdk.transactions
        .transition(
            { id, transition, params },
            { expand: true, include, ...IMAGE_VARIANTS }
        )
        .then((response) => {
            dispatch(addMarketplaceEntities(response));
            dispatch(sendReviewSuccess());
            return response;
        })
        .catch((e) => {
            // If transaction transition is invalid, lets try another endpoint.
            if (isTransactionsTransitionInvalidTransition(e)) {
                return sendReviewAsSecond(id, params, role, dispatch, sdk);
            } else {
                dispatch(sendReviewError(storableError(e)));

                // Rethrow so the page can track whether the sending failed, and
                // keep the message in the form for a retry.
                throw e;
            }
        });
};

export const sendReview =
    (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
        const params = { reviewRating, reviewContent };

        const txStateOtherPartyFirst = txIsInFirstReviewBy(
            tx,
            role !== CUSTOMER
        );

        dispatch(sendReviewRequest());

        return txStateOtherPartyFirst
            ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
            : sendReviewAsFirst(tx.id, params, role, dispatch, sdk);
    };

const isNonEmpty = (value) => {
    return typeof value === 'object' || Array.isArray(value)
        ? !isEmpty(value)
        : !!value;
};

export const fetchNextTransitions = (id) => (dispatch, getState, sdk) => {
    dispatch(fetchTransitionsRequest());

    return sdk.processTransitions
        .query({ transactionId: id })
        .then((res) => {
            dispatch(fetchTransitionsSuccess(res.data.data));
        })
        .catch((e) => {
            dispatch(fetchTransitionsError(storableError(e)));
        });
};

export const fetchTransactionLineItems =
    ({ bookingData, listingId, isOwnListing }) =>
        (dispatch) => {
            dispatch(fetchLineItemsRequest());
            transactionLineItems({ bookingData, listingId, isOwnListing })
                .then((response) => {
                    const lineItems = response.data;
                    dispatch(fetchLineItemsSuccess(lineItems));
                })
                .catch((e) => {
                    dispatch(fetchLineItemsError(storableError(e)));
                    log.error(e, 'fetching-line-items-failed', {
                        listingId: listingId.uuid,
                        bookingData: bookingData,
                    });
                });
        };

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params) => (dispatch, getState) => {
    const txId = new UUID(params.id);
    const state = getState().TransactionPage;
    const txRef = state.transactionRef;
    const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
    dispatch(setInitialValues(initialValues));

    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
        dispatch(fetchTransaction(txId, txRole)),
        dispatch(fetchMessages(txId, 1)),
        dispatch(fetchFiles(txId.uuid)),
        dispatch(fetchSysMsg(txId.uuid)),
        dispatch(fetchOffers(txId.uuid)),
        dispatch(fetchNextTransitions(txId)),
    ]);
};
