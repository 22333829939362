import React, { useRef } from 'react';
import _ from 'lodash';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import {
    Avatar,
    InlineTextButton,
    ReviewRating,
    UserDisplayName,
    SecondaryButton,
} from '../../components';
import config from '../../config';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
    TRANSITION_ENQUIRE,
    TRANSITION_CANCEL_ENQUIRY,
    TRANSITION_DECLINE_ENQUIRY,
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_COMPLETE,
    TRANSITION_OPERATOR_COMPLETE,
    TRANSITION_PAYOUT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_REQUEST_CANCEL,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    transitionIsReviewed,
    txIsDelivered,
    txIsInFirstReviewBy,
    txIsReviewed,
    isCustomerReview,
    isProviderReview,
    txRoleIsProvider,
    txRoleIsCustomer,
    getUserTxRole,
    isRelevantPastTransition,
    TRANSITION_OPERATOR_PAYOUT,
    TRANSITION_INSTANT_BOOKING,
    TRANSITION_ACCEPT_FILE_OFFER,
    TRANSITION_COMPLETE_FILE_OFFER,
    TRANSITION_OPERATOR_COMPLETE_FILE_OFFER,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';
import SysMsgComponent from './SysMsgComponents';
import OfferComponent from './OfferComponent';
import FileComponent from './FileComponent';
import { ReactComponent as UploadIcon } from './icons/upload.svg';
import css from './ActivityFeed.module.css';

const UploadInvoiceInDays = config.uploadInvoiceInDays;

const SystemMessage = (props) => {
    const { messageText } = props;
    return (
        <div className={css.message}>
            <div>
                <p className={css.systemMessageContent}>
                    {messageText}
                </p>
            </div>
        </div>
    );
};

const Message = (props) => {
    const { message, intl } = props;
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
    const isCustomOfferMessage = message.attributes.content.includes(") has been added, you have 48 hours to accept the offer.")
    return (
        <div className={css.message}>
            {!isCustomOfferMessage && <Avatar
                className={css.avatar}
                user={message.sender}
                disableProfileLink
            />}
            <div>
                <p className={isCustomOfferMessage ? css.systemMessageContent : css.messageContent}>
                    {message.attributes.content}
                </p>
                <p className={css.messageDate}>
                    {formatDate(
                        intl,
                        todayString,
                        message.attributes.createdAt
                    )}
                </p>
            </div>
        </div>
    );
};

Message.propTypes = {
    message: propTypes.message.isRequired,
    intl: intlShape.isRequired,
};

const OwnMessage = (props) => {
    const { message, intl } = props;
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
    const isCustomOfferMessage = message.attributes.content.includes(") has been added, you have 48 hours to accept the offer.")
    if (isCustomOfferMessage && props.isProvider) return <></>
    return (
        <div className={css.ownMessage}>
            <div className={css.ownMessageContentWrapper}>
                <p className={isCustomOfferMessage ? css.systemMessageContent : css.ownMessageContent}>
                    {message.attributes.content}
                </p>
            </div>
            <p className={css.ownMessageDate}>
                {formatDate(intl, todayString, message.attributes.createdAt)}
            </p>
        </div>
    );
};

OwnMessage.propTypes = {
    message: propTypes.message.isRequired,
    intl: intlShape.isRequired,
};

const Review = (props) => {
    const { content, rating } = props;
    return (
        <div>
            <p className={css.reviewContent}>{content}</p>
            {rating ? (
                <ReviewRating
                    reviewStarClassName={css.reviewStar}
                    className={css.reviewStars}
                    rating={rating}
                />
            ) : null}
        </div>
    );
};

Review.propTypes = {
    content: string.isRequired,
    rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
    // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
    // we should also use isCustomer insted of isProvider
    const isCustomer = txRoleIsCustomer(userRole);
    return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
) => {
    const isOwnTransition = transition.by === ownRole;
    const currentTransition = transition.transition;
    const displayName = otherUsersName;

    switch (currentTransition) {
        case TRANSITION_ENQUIRE:
            return isOwnTransition ? (
                <FormattedMessage
                    id="ActivityFeed.ownTransitionInquire"
                />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionInquiry"
                    values={{ displayName }}
                />
            );
        // instant booking transition is handled differently before coming here
        case TRANSITION_INSTANT_BOOKING:
            return isOwnTransition ? (
                <FormattedMessage
                    id="ActivityFeed.ownTransitionRequest"
                    values={{ listingTitle }}
                />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionRequest"
                    values={{ displayName, listingTitle }}
                />
            );
        case TRANSITION_ACCEPT:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionAccept"
                    values={{ displayName }}
                />
            );
        case TRANSITION_REQUEST_CANCEL:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.ownTransitionRequestCancel" />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionRequestCancel"
                    values={{ displayName }}
                />
            );
        case TRANSITION_DECLINE:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionDecline"
                    values={{ displayName }}
                />
            );
        case TRANSITION_EXPIRE:
            return txRoleIsProvider(ownRole) ? (
                <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.transitionExpire"
                    values={{ displayName }}
                />
            );
        case TRANSITION_CANCEL:
            return <FormattedMessage id="ActivityFeed.transitionCancel" />;
        case TRANSITION_CANCEL_ENQUIRY:
            return <FormattedMessage id="ActivityFeed.transitionInquiryCancel" />;
        case TRANSITION_DECLINE_ENQUIRY:
            return <FormattedMessage id="ActivityFeed.transitionInquiryCancel" />;
        // case TRANSITION_COMPLETE:
        // case TRANSITION_OPERATOR_COMPLETE:
        case TRANSITION_COMPLETE_FILE_OFFER:
        case TRANSITION_OPERATOR_COMPLETE_FILE_OFFER:
        case TRANSITION_PAYOUT:
        case TRANSITION_OPERATOR_PAYOUT:
            // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
            const reviewPeriodJustStarted = txIsDelivered(transaction);

            const reviewAsFirstLink = reviewPeriodJustStarted ? (
                <InlineTextButton onClick={onOpenReviewModal}>
                    <FormattedMessage
                        id="ActivityFeed.leaveAReview"
                        values={{ displayName }}
                    />
                </InlineTextButton>
            ) : null;

            return (
                <FormattedMessage
                    id="ActivityFeed.transitionComplete"
                    values={{ reviewLink: reviewAsFirstLink }}
                />
            );

        case TRANSITION_REVIEW_1_BY_PROVIDER:
        case TRANSITION_REVIEW_1_BY_CUSTOMER:
            if (isOwnTransition) {
                return (
                    <FormattedMessage
                        id="ActivityFeed.ownTransitionReview"
                        values={{ displayName }}
                    />
                );
            } else {
                // show the leave a review link if current user is not the first
                // one to leave a review
                const reviewPeriodIsOver = txIsReviewed(transaction);
                const userHasLeftAReview = hasUserLeftAReviewFirst(
                    ownRole,
                    transaction
                );
                const reviewAsSecondLink = !(
                    reviewPeriodIsOver || userHasLeftAReview
                ) ? (
                    <InlineTextButton onClick={onOpenReviewModal}>
                        <FormattedMessage
                            id="ActivityFeed.leaveAReviewSecond"
                            values={{ displayName }}
                        />
                    </InlineTextButton>
                ) : null;
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReview"
                        values={{ displayName, reviewLink: reviewAsSecondLink }}
                    />
                );
            }
        case TRANSITION_REVIEW_2_BY_PROVIDER:
        case TRANSITION_REVIEW_2_BY_CUSTOMER:
            if (isOwnTransition) {
                return (
                    <FormattedMessage
                        id="ActivityFeed.ownTransitionReview"
                        values={{ displayName }}
                    />
                );
            } else {
                return (
                    <FormattedMessage
                        id="ActivityFeed.transitionReview"
                        values={{ displayName, reviewLink: null }}
                    />
                );
            }

        default:
            log.error(
                new Error('Unknown transaction transition type'),
                'unknown-transition-type',
                {
                    transitionType: currentTransition,
                }
            );
            return '';
    }
};

const reviewByAuthorId = (transaction, userId) => {
    return transaction.reviews.filter(
        (r) => !r.attributes.deleted && r.author.id.uuid === userId.uuid
    )[0];
};

const isCancelTransition = (transition) => {
    const cancelTransitionList = [
        TRANSITION_CANCEL,
        TRANSITION_DECLINE,
        TRANSITION_EXPIRE,
        TRANSITION_REQUEST_CANCEL,
    ];

    return cancelTransitionList.includes(transition.transition);
};

const Transition = (props) => {
    const { transition, transaction, currentUser, intl, onOpenReviewModal } = props;

    const currentTransaction = ensureTransaction(transaction);
    const customer = currentTransaction.customer;
    const provider = currentTransaction.provider;

    const deletedListing = intl.formatMessage({
        id: 'ActivityFeed.deletedListing',
    });
    const listingTitle = currentTransaction.listing.attributes.deleted
        ? deletedListing
        : currentTransaction.listing.attributes.title;
    const lastTransition = currentTransaction.attributes.lastTransition;

    const ownRole = getUserTxRole(currentUser.id, currentTransaction);
    const isProviderRole = txRoleIsProvider(ownRole);
    const theOtherUser = isProviderRole ? customer : provider;

    const otherUsersName = isProviderRole ? (
        <UserDisplayName user={customer} intl={intl} />
    ) : (
        <UserDisplayName user={provider} intl={intl} />
    );

    const transitionMessage = resolveTransitionMessage(
        transaction,
        transition,
        listingTitle,
        ownRole,
        otherUsersName,
        intl,
        onOpenReviewModal
    );
    const shouldEmphasisTransitionMessage = isCancelTransition(transition);
    const acceptedTransitionMessage = transition?.transition === TRANSITION_ACCEPT;
    const currentTransition = transition.transition;

    const deletedReviewContent = intl.formatMessage({
        id: 'ActivityFeed.deletedReviewContent',
    });
    let reviewComponent = null;

    if (transitionIsReviewed(lastTransition)) {
        if (isCustomerReview(currentTransition)) {
            const review = reviewByAuthorId(currentTransaction, customer.id);
            reviewComponent = review ? (
                <Review
                    content={review.attributes.content}
                    rating={review.attributes.rating}
                />
            ) : (
                <Review content={deletedReviewContent} />
            );
        } else if (isProviderReview(currentTransition)) {
            const review = reviewByAuthorId(currentTransaction, provider.id);
            reviewComponent = review ? (
                <Review
                    content={review.attributes.content}
                    rating={review.attributes.rating}
                />
            ) : (
                <Review content={deletedReviewContent} />
            );
        }
    }

    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

    if (currentTransition == TRANSITION_INSTANT_BOOKING || currentTransition == TRANSITION_ACCEPT_FILE_OFFER) {
        return <SysMsgComponent
            sysMsgType="static"
            sysMsg={{ type: "accepted" }}
            contactDetails={{
                name: otherUsersName,
                phone: theOtherUser.attributes.profile.publicData.phoneNumber,
                address: isProviderRole ? null : currentTransaction.listing.attributes.publicData.location.address,
            }}
        />
    };

    return (
        <div className={css.transition}>
            <div className={css.bullet}>
                <p className={css.transitionContent}>•</p>
            </div>
            <div>
                <p
                    className={
                        shouldEmphasisTransitionMessage
                            ? css.emphasisTransitionContent
                            : acceptedTransitionMessage
                                ? css.acceptedTransitionContent
                                : css.transitionContent
                    }>
                    {transitionMessage}
                </p>
                <p className={css.transitionDate}>
                    {formatDate(intl, todayString, transition.createdAt)}
                </p>
                {reviewComponent}
            </div>
        </div>
    );
};

Transition.propTypes = {
    transition: propTypes.transition.isRequired,
    transaction: propTypes.transaction.isRequired,
    currentUser: propTypes.currentUser.isRequired,
    intl: intlShape.isRequired,
    onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
    return (
        <div className={css.transition}>
            <div className={css.bullet}>
                <p className={css.transitionContent}>•</p>
            </div>
            <div>
                <p className={css.transitionContent} />
                <p className={css.transitionDate} />
            </div>
        </div>
    );
};

const isMessage = (item) => item && item.type === 'message';
const isSysMsg = (item) => item && item.sysMsgType || item && item.decline_reason;
const isOffer = (item) => item && item.offerType;
const isFile = (item) => item && item.file_type;

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
    const itemDate = (item) =>
        isMessage(item) ? item.attributes.createdAt : new Date(item.createdAt);
    return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, nonOfferFiles, offers, sysMsgs, hideOldTransitions) => {
    const items = messages.concat(transitions).concat(nonOfferFiles).concat(offers).concat(sysMsgs).sort(compareItems);
    if (hideOldTransitions) {
        // Hide transitions that happened before the oldest message. Since
        // we have older items (messages) that we are not showing, seeing
        // old transitions would be confusing.
        return dropWhile(items, (i) => !isMessage(i) && !isOffer(i) && !isSysMsg(i) && !isFile(i));
    } else {
        return items;
    }
};

export const ActivityFeedComponent = (props) => {
    const {
        rootClassName,
        className,
        messages,
        transaction,
        currentUser,
        hasOlderMessages,
        onOpenReviewModal,
        onShowOlderMessages,
        fetchMessagesInProgress,
        intl,
        submitG4,
        actionInProgress,
        actionError,
        onEditInquiry,
        onCancelInquiry,
        onSubmitBookingRequest,
        customBreakdownComponent,
        fetchOffersInProgress,
        fetchOffersError,
        offers,
        sendOfferInProgress,
        sendOfferError,
        onUpdateOffer,
        onAcceptFileOffer,
        onHandleG4,
        lineItems,
        onSendOffer: onCreateOffer,
        onUploadOffer,
        tempFileId,
        files,
        sendFileInProgress,
        sendFileError,
        onUploadFile,
        sysMsgs,
        inquiryDataAvailable,
    } = props;

    const fileInput = useRef(null);

    const classes = classNames(rootClassName || css.root, className);

    const currentTransaction = ensureTransaction(transaction);
    const transitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentListing = ensureListing(currentTransaction.listing);

    const ownRole = getUserTxRole(currentUser.id, currentTransaction);
    const isProvider = txRoleIsProvider(ownRole);

    const isInquiry = transaction.attributes.lastTransition == TRANSITION_ENQUIRE;
    const isInquiryAccepted = transaction.attributes.metadata.inquiryAccepted;

    const txIsSupported = transaction.attributes.processVersion >= config.minTxSupportedVersion;

    const transitionsAvailable = !!(
        currentUser &&
        currentUser.id &&
        currentCustomer.id &&
        currentProvider.id &&
        currentListing.id
    );

    let askToUploadInvoice = false;
    const acceptedFileOffer = offers.find(o => o.offerStatus == "accepted" && o.offerType == "file");

    if (acceptedFileOffer && isProvider) {
        const bookingEnd = currentTransaction?.booking?.attributes?.end;
        const foundInvoice = files.find(f => f.file_type == "invoice");

        if (bookingEnd && !foundInvoice) {
            const clonedBookingEnd = _.cloneDeep(new Date(bookingEnd));
            const startAskingDate = new Date(clonedBookingEnd.setDate(clonedBookingEnd.getDate() + UploadInvoiceInDays));
            if (new Date() >= startAskingDate) {
                askToUploadInvoice = true;
            };
        };
    };

    const nonOfferFiles = files.filter(f => f.file_type !== "offer");

    // combine messages and transaction transitions
    const items = organizedItems(
        messages,
        transitions,
        nonOfferFiles,
        offers,
        sysMsgs,
        hasOlderMessages || fetchMessagesInProgress
    );

    if (txIsSupported && isProvider && messages.length > 2 && offers.length == 0) {
        items.splice(4, 0, {
            sysMsgType: "action",
            isReminder: true,
            loading: fetchOffersInProgress || sendOfferInProgress,
            role: "provider",
            customerName: <UserDisplayName user={currentTransaction.customer} intl={intl} />,
            onCreateOffer: () => onCreateOffer(),
            onUploadOffer,
            errorMsg: fetchOffersError || sendOfferError,
        });
    };

    const transitionComponent = (transition) => {
        if (transitionsAvailable) {
            return (
                <Transition
                    transition={transition}
                    transaction={transaction}
                    currentUser={currentUser}
                    intl={intl}
                    onOpenReviewModal={onOpenReviewModal}
                />
            );
        } else {
            return <EmptyTransition />;
        }
    };

    const messageComponent = (message) => {
        const isOwnMessage =
            message.sender &&
            message.sender.id &&
            currentUser &&
            currentUser.id &&
            message.sender.id.uuid === currentUser.id.uuid;
        if (isOwnMessage) {
            return <OwnMessage message={message} intl={intl} isProvider={isProvider} />;
        }
        return <Message message={message} intl={intl} />;
    };

    const messageListItem = (message) => {
        return (
            <li
                id={`msg-${message.id.uuid}`}
                key={message.id.uuid}
                className={css.messageItem}>
                {messageComponent(message)}
            </li>
        );
    };

    const transitionListItem = (transition) => {
        if (isRelevantPastTransition(transition.transition)) {
            return (
                <li key={transition.transition} className={css.transitionItem}>
                    {transitionComponent(transition)}
                </li>
            );
        } else {
            return null;
        }
    };

    const handleFileChange = event => {
        const tempFile = event.target.files[0];
        const formData = new FormData();
        formData.append("file", tempFile);
        formData.append("file_type", "invoice");
        formData.append('name', tempFile.name);
        formData.append('tx_id', currentTransaction.id.uuid);
        formData.append('user_id', currentProvider.id.uuid);
        onUploadFile(formData);
    };

    return (<>
        <div>
            <SystemMessage messageText={<FormattedMessage id="ActivityFeed.contactRobinRoom" />} />
        </div>
        <ul className={classes}>
            {hasOlderMessages ? (
                <li className={css.showOlderWrapper} key="show-older-messages">
                    <InlineTextButton
                        className={css.showOlderButton}
                        onClick={onShowOlderMessages}>
                        <FormattedMessage id="ActivityFeed.showOlderMessages" />
                    </InlineTextButton>
                </li>
            ) : null}
            {items.map((item) => {
                if (isMessage(item)) {
                    return messageListItem(item);
                } else if (isFile(item)) {
                    const currentUserUUID = currentUser?.id?.uuid;
                    const fileSender = currentUserUUID == item.user_id ? "me" : "other";

                    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
                    const formattedDate = formatDate(intl, todayString, new Date(item.createdAt));
                    return <FileComponent
                        {...item}
                        fileSender={fileSender}
                        formattedDate={formattedDate}
                    />
                } else if (isSysMsg(item)) {
                    if (txIsSupported) {
                        if (item.isReminder) {
                            return isInquiry ? <SysMsgComponent
                                {...item}
                            /> : null;
                        } else {
                            return <SysMsgComponent
                                sysMsgType={item.sysMsgType ? item.sysMsgType : 'static'}
                                sysMsg={item}
                                role={ownRole}
                                customerName={<UserDisplayName user={currentTransaction.customer} intl={intl} />}
                            />
                        };
                    };
                } else if (isOffer(item)) {
                    if (txIsSupported) {
                        const isLastOffer = offers[offers.length - 1]?._id == item._id;
                        const offerBreakdown = isLastOffer ? customBreakdownComponent : null;
                        return <OfferComponent
                            intl={intl}
                            offer={item}
                            isLastOffer={isLastOffer}
                            isInquiry={isInquiry}
                            offerFile={item.attachedFileId ? files.find(file => file._id == item.attachedFileId) : null}
                            role={ownRole}
                            currentTransaction={currentTransaction}
                            listingId={currentListing.id}
                            customerId={currentTransaction.customer.id}
                            offerBreakdown={offerBreakdown}
                            activity={currentTransaction.attributes.metadata?.rawBookingData?.activity}
                            sendOfferInProgress={sendOfferInProgress}
                            onUpdateOffer={onUpdateOffer}
                            onAcceptFileOffer={onAcceptFileOffer}
                            onHandleG4={onHandleG4}
                            lineItems={lineItems}
                            onSubmitBookingRequest={onSubmitBookingRequest}
                            customerName={<UserDisplayName user={currentTransaction.customer} intl={intl} />}
                            providerName={<UserDisplayName user={currentTransaction.provider} intl={intl} />}
                            onSetOfferForUpdate={() => onCreateOffer(item._id)}
                        />
                    };
                } else {
                    return transitionListItem(item);
                }
            })}

            {isInquiry && txIsSupported ? <SysMsgComponent
                loading={actionInProgress || fetchOffersInProgress || sendOfferInProgress}
                sysMsgType="action"
                role={ownRole}
                txHasOffer={offers.length > 0}
                customerName={<UserDisplayName user={currentTransaction.customer} intl={intl} />}
                providerName={<UserDisplayName user={currentTransaction.provider} intl={intl} />}
                isInquiryAccepted={isInquiryAccepted}
                inquiryDataAvailable={inquiryDataAvailable}
                onDeclineInquiry={() => submitG4('decline')}
                onCancelInquiry={onCancelInquiry}
                onAcceptInquiry={() => submitG4('accept')}
                onCreateOffer={() => onCreateOffer()}
                onEditInquiry={onEditInquiry}
                onConfirmInquiry={() => {
                    const bookingStartTime = currentTransaction.attributes.metadata?.rawBookingData?.bookingStartTime;

                    if (bookingStartTime && new Date(Number(bookingStartTime)) > new Date()) {
                        onHandleG4({
                            txId: currentTransaction.id,
                            isFile: false,
                            isNoOffer: true,
                            activity: currentTransaction.attributes.metadata?.rawBookingData?.activity,
                            lineItems,
                        });
                        onSubmitBookingRequest(currentTransaction.attributes.metadata.rawBookingData);
                    } else {
                        alert("Invalid booking dates");
                    };
                }}
                errorMsg={actionError}
            /> : null}

            {askToUploadInvoice ? <div>
                <input
                    id="file"
                    name="file"
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={(e) => handleFileChange(e)}
                    ref={fileInput}
                />

                <SecondaryButton
                    type="button"
                    className={css.uploadPDF}
                    inProgress={sendFileInProgress}
                    ready={tempFileId}
                    disabled={sendFileInProgress || sendFileError}
                    onClick={() => fileInput.current.click()}
                >
                    <UploadIcon />
                    <FormattedMessage id="ActivityFeed.uploadInvoice" />
                </SecondaryButton>
                {sendFileError && <p style={{ color: 'red' }}>{sendFileError}</p>}
            </div> : null}
        </ul>
    </>
    );
};

ActivityFeedComponent.defaultProps = {
    rootClassName: null,
    className: null,
};

ActivityFeedComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction,
    messages: arrayOf(propTypes.message),
    hasOlderMessages: bool.isRequired,
    onOpenReviewModal: func.isRequired,
    onShowOlderMessages: func.isRequired,
    fetchMessagesInProgress: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
